<template>
	<div>
		<div class="flex items-center justify-between border rounded-t-sm px-4 bg-white shadow-lg">
            <div>
                <stepper>
                    <li><button :class="{'active': form.status == 'Draft'}">DRAFT</button></li>
                    <li><button :class="{'active': form.status == 'Posted'}">POSTED</button></li>
                    <li v-if="form.status == 'Cancelled'"><button :class="{'active': form.status == 'Cancelled'}">CANCELLED</button></li>
                </stepper>
            </div>
            <div v-if="form.status == 'Draft'">
                <button @click="onCanceled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button @click="onConfirm" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">CONFIRM</button>
            </div>
            <div v-if="form.status == 'Posted'">
                <button @click="onCanceled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button v-if="form.paymentStatus == 'In Payment' || form.paymentStatus == 'Paid' || form.paymentStatus == 'Partial'"  @click="onPrint" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">PRINT</button>
                <button v-if="form.paymentStatus == 'Not Paid'" @click="onPrintProForma" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">PRO-FORMA INVOICE</button>
                <button v-if="form.paymentStatus == 'Not Paid' || form.paymentStatus == 'Partial'" @click="modalPayment = true" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">REGISTER PAYMENT</button>
            </div>
        </div>
		<div class="m-5 relative">
            <!-- BEDGE PAYMENT STATUS -->
            <div v-show="tab == 'overview'" class="absolute overflow-hidden right-0 z-10 w-36 h-36">
                <div class="relative h-full w-full">
                    <div v-show="form.paymentStatus != 'Not Paid'" class="bg-green-500 top-6 -right-14 absolute h-12 w-52 transform bottom-0 rotate-45 flex items-center justify-center shadow-md">
                        <div class="text-lg text-green-50 font-medium uppercase">{{form.paymentStatus}}</div>
                    </div>
                </div>
            </div>

			<div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                        <div @click="onTab('task')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='task'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">TASK & NOTES <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{task}}</span></div>
                    </div>
                </div>
            </div>
			<!-- OVERVIEW -->
			<div v-show="tab == 'overview'" class="animated fadeIn faster">
				<div class="grid grid-cols-10 p-5 divide-x bg-white border border-t-0 border-b-0">
					<div class="col-span-6 pr-5">
						<div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{form.invoiceNo}}</div>
                                <div class="text-gray-400 text-xs">CUSTOMER INVOICE #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">{{form.salesNo}}</div>
                                <div class="text-gray-400 text-xs">SALES ORDER #</div>
                            </div>
                        </div>
						<div class="mb-3">
                            <label for="" class="font-medium">CUSTOMER</label>
                            <div class="grid grid-cols-2">
                                <div class="col-span-1">
                                <label for="" class="font-medium">Bill To</label>
                                    <div class="text-base font-medium">{{form.billTo.displayName}}</div>
                                    <div v-if="form.billTo.customer">
                                        <div>{{form.billTo.customer.address.street}}</div>
                                        <div>{{form.billTo.customer.address.street2}}</div>
                                        <div>{{form.billTo.customer.address.subdistrictName}} {{form.billTo.customer.address.cityName}} {{form.billTo.customer.address.zip}}</div>
                                        <div>{{form.billTo.customer.address.provinceName}}</div>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <label for="" class="font-medium">Ship To</label>
                                    <div class="text-base font-medium">{{form.shipTo.displayName}}</div>
                                    <div v-if="form.shipTo.address">
                                        <div>{{form.shipTo.address.street}}</div>
                                        <div>{{form.shipTo.address.street2}}</div>
                                        <div>{{form.shipTo.address.subdistrictName}} {{form.shipTo.address.cityName}} {{form.shipTo.address.zip}}</div>
                                        <div>{{form.shipTo.address.provinceName}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>

					<div class="col-span-4 pl-5">
                        <!-- ON EDIT -->
                        <div v-show="isEdited">
                            <div class="grid grid-cols-2 gap-5 mb-3">
                                <div class="col-span-1">
                                    <label class="font-medium" for="">INVOICE DATE</label>
                                    <div>{{getDate(form.createdAt)}}</div>
                                </div>
                                <div class="col-span-1">
                                    <label class="font-medium" for="">DUE DATE</label>
                                    <div class="mt-2">
                                        <date-picker v-model="form.dueDate" :masks="masks">
                                            <template v-slot="{inputValue, inputEvents}">
                                                <div class="flex">
                                                    <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-calendar3"></i></span>
                                                    <input :disabled="status" :value="inputValue" v-on="inputEvents" type="text" class="form-control rounded-tl-none rounded-bl-none border-l-0 text-blue-500">
                                                </div>
                                            </template>
                                        </date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="" class="font-medium">BANK TRANSFER</label>
                                <div class="flex flex-col mt-2">
                                    <select-2 ref="banks" v-model="form.bankId" class="form-control" :placeholder="'Select Bank account'">
                                        <option value=""></option>
                                        <option v-for="(bank, i) in bankLists" :data-foo="bank.accountName" :key="i" :value="bank._id">{{bank.name}}</option>
                                    </select-2>
                                </div>
                            </div>
                        </div>

                        <!-- ON SAVE -->
                        <div v-show="!isEdited">
                            <div class="grid grid-cols-2 gap-5 mb-3">
                                <div class="col-span-1">
                                    <label class="font-medium" for="">INVOICE DATE</label>
                                    <div>{{getDate(form.createdAt)}}</div>
                                </div>
                                <div class="col-span-1">
                                    <label class="font-medium" for="">DUE DATE</label>
                                    <div>{{getDate(form.dueDate)}}</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="" class="font-medium">TERM OF PAYMENT</label>
                                <div v-if="form.paymentTerm">
                                    <div>{{form.paymentTerm.code}}</div>
                                </div>
                                <div v-else>--</div>
                            </div>
                            <div class="mb-3">
                                <label for="" class="font-medium">BANK TRANSFER</label>
                                <div v-if="form.bank">
                                    <div>{{form.bank.name}}</div>
                                    <div>{{form.bank.accountNumber}}</div>
                                    <div>A/C Name: {{form.bank.accountName}}</div>
                                </div>
                                <div v-else>--</div>
                            </div>
                        </div>
					</div>
				</div>

				<table v-show="form.type == 'Regular'" class="w-full table-fixed bg-white">
					<thead class="border border-blue-500">
                        <tr class="bg-blue-500 border-b">
                            <th class="py-2.5 pl-5 pr-2  text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                            <th class="py-2.5 px-2 text-blue-50 bg-blue-500" style="width: 40%; top: 93px">ITEM</th>
                            <th class="py-2.5 px-2 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">UNIT PRICE</th>
                            <th class="py-2.5 px-2 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">QTY</th>
                            <th class="py-2.5 px-2 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">DISCOUNT</th>
                            <th class="py-2.5 pl-2 pr-5 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody ref="body" class="border border-b-0"> 
                        <tr v-for="(item, i) in items" :key="i" @click="onEdit(i)" class="border-b hover:bg-gray-100 group cursor-pointer">
                            <td :ref="'td'+i" class="pl-5 h-9 border-r relative" :class="{'bg-gray-100': item.edited}">
                                <div v-show="item.edited" :ref="'tick'+i" class="absolute -left-px -top-px w-2 bg-teal-500"></div>
                                {{i+1}}
                            </td>
                            <td class="px-2 h-9 border-r border-transparent truncate" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">{{item.name}}</td>
                            <td class="px-2 h-9 border-r border-transparent text-right" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.unitPrice)}}</td>
                            <td class="border-r border-transparent">
                                <autonumeric
                                    :disabled="status"
                                    :ref="'qty'+i"
                                    :class="[{'group-hover:bg-white': item.edited}, {'bg-red-500 text-red-50 group-hover:bg-red-500': item.err}]"
                                    @keydown.native.enter="onEnter(i)"
                                    v-model="item.qty"
                                    class="h-9 w-full focus:outline-none px-2 text-right group-hover:bg-gray-100"
                                    :options="['integer', {modifyValueOnWheel : false}]" 
                                >
                                </autonumeric>
                            </td>
                            <td v-if="item.discount.amount" class="h-9 px-2 text-right text-red-500" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">
                                <div v-if="item.discount.discountType == 'Fixed'">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.discount.amount)}}</div>
                                <div v-else>{{item.discount.amount}}%</div>
                            </td>
                            <td v-else class="h-9 px-2 text-right" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">--</td>
                            <td class="h-9 pl-2 pr-5 text-right" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.subTotal)}}</td>
                        </tr>
                    </tbody>
                    <tbody v-if="form.type == 'Regular'">
                        <!-- SUBTOTAL -->
                        <tr>
                            <td colspan="5" class="h-9 px-2 text-right">Subtotal</td>
                            <td class="pt-3 pb-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(total)}}</td>
                        </tr>
                        <!-- DISCOUNT -->
                        <tr v-if="form.discount.value" class="font-medium text-red-500">
                            <td colspan="5" class="px-2 py-1 text-right">Discount on total price</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.discount.value)}}</td>
                        </tr>
                         <!-- TAX -->
                        <tr v-if="form.tax.value" class="font-medium text-blue-500">
                            <td colspan="5" class="px-2 text-right">
                                {{form.tax.code}} ({{form.tax.amount}}%)
                            </td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(tax)}}</td>
                        </tr>
                          <!-- ADDITIONAL CHARGES -->
                        <tr v-if="form.totalAdditionalCharges" class="font-medium text-green-600">
                            <td colspan="5" class="px-2 py-1 text-right">ADDITIONAL CHARGERS	</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.totalAdditionalCharges)}}</td>
                        </tr>
                        <!-- SHIPPING -->
                        <tr v-if="form.shipping.shipmentCost" class="font-medium">
                            <td colspan="5" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.shipping.shipmentCost)}}</td>
                        </tr>
                        <!-- TOTAL -->
                        <tr class="">
                            <td colspan="5" class="text-right text-base px-2 font-semibold h-9">TOTAL</td>
                            <td class="text-right text-base font-semibold pl-2 pr-5">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(grandTotal)}}</td>
                        </tr>
                        <!-- PAID -->
                        <tr v-for="(payment, p) in payments" :key="p">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colspan="2" class="text-right px-2 h-6 italic border-t">Paid On {{getDate(payment.paymentDate)}}</td>
                            <td class="text-right pl-2 pr-5 border-t">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(payment.amount)}}</td>
                        </tr>
                        <!-- AMOUNT DUE -->
                        <tr class="bg-orange-100">
                            <td colspan="5" class="text-right text-base px-2 font-semibold h-9">AMOUNT DUE</td>
                            <td class="text-right text-base font-semibold pl-2 pr-5">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(amount)}}</td>
                        </tr>
                    </tbody>
				</table>

                <!-- DOWN PAYMENT TABLE -->
                <table v-show="form.type != 'Regular'" class="w-full">
                    <thead class="border border-blue-500">
                        <tr class="bg-blue-500 border-b">
                            <th class="py-2.5 pl-5 pr-2  text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                            <th class="py-2.5 px-2 text-blue-50 bg-blue-500" style="width: 40%; top: 93px">ITEM</th>
                            <th class="py-2.5 px-2 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">UNIT PRICE</th>
                            <th class="py-2.5 px-2 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">QTY</th>
                            <th class="py-2.5 px-2 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">DISCOUNT</th>
                            <th class="py-2.5 pl-2 pr-5 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody class="border border-b-0">
                        <tr v-for="(dp, a) in form.downPayments" :key="a" class="border-b">
                            <td class="pl-5 px-2 border-r">{{a+1}}</td>
                            <td class="h-9 px-2">{{dp.name}}</td>
                            <td class="h-9 px-2 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(dp.amount)}}</td>
                            <td class="h-9 px-2 text-right">1</td>
                            <td class="h-9 px-2 text-right">--</td>
                            <td class="h-9 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(dp.amount)}}</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <!-- TOTAL -->
                        <tr class="">
                            <td colspan="5" class="text-right text-base px-2 font-semibold h-9">TOTAL</td>
                            <td class="text-right text-base font-semibold pl-2 pr-5">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(grandTotal)}}</td>
                        </tr>
                        <!-- PAID -->
                        <tr v-for="(payment, p) in payments" :key="p">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colspan="2" class="text-right px-2 h-6 italic border-t">Paid On {{getDate(payment.paymentDate)}}</td>
                            <td class="text-right pl-2 pr-5 border-t">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(payment.amount)}}</td>
                        </tr>
                        <!-- AMOUNT DUE -->
                        <tr class="bg-orange-100">
                            <td colspan="5" class="text-right text-base px-2 font-semibold h-9">AMOUNT DUE</td>
                            <td class="text-right text-base font-semibold pl-2 pr-5">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(amount)}}</td>
                        </tr>
                    </tbody>
                </table>
			</div>

            <!-- TASK & NOTE -->
            <div v-show="tab == 'task'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <task-manager v-model="task" :documentName="form.invoiceNo"></task-manager>   
            </div>
		</div>

        <!-- MODAL REGISTER PAYMENT -->
        <modal :show="modalPayment">
            <form @submit.prevent="onSubmitPayment" class="w-2/3 bg-white border rounded-sm">
                <div class="flex items-center justify-between px-5 py-3 border-b">
                    <div class="font-medium">REGISTER PAYMENT</div>
                </div>
                <div class="p-5 mb-5">
                    <div class="grid grid-cols-2 gap-16">
                        <div class="col-span-1">
                            <div class="mb-5">
                                <div class="font-medium text-base">{{formPayment.customerName}}</div>
                                <div class="text-gray-400 text-xs">CUSTOMER</div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Journal</label>
                                </div>
                                <div class="col-span-5 mb-2">
                                    <dropdown class="w-full" v-model="formPayment.journal" :items="journals">
                                        <template #default="data">
                                            <div class="cursor-pointer border-b  bg-white flex items-center">
                                                <button class="w-full flex h-full items-center focus:outline-none">
                                                    <span  class="mr-auto flex items-center">{{formPayment.journal}}</span>
                                                    <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #items="items">
                                            <div>{{items.item.name}}</div>
                                        </template>
                                    </dropdown>
                                </div>
                            </div>
                            <div v-show="formPayment.journal == 'Bank'" class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Account Bank</label>
                                </div>
                                <div class="col-span-5">
                                    <dropdown class="w-full" v-model="formPayment.bankName" :items="banks">
                                        <template #default="data">
                                            <div class="cursor-pointer border-b  bg-white flex items-center" :class="{'border-red-500': errPayment.bankId}">
                                                <button class="w-full flex h-full items-center justify-between focus:outline-none">
                                                    <span v-if="formPayment.bankName">{{formPayment.bankName}}</span>
                                                    <span v-else class="text-gray-400">Select account bank</span>
                                                    <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #items="items">
                                            <div>{{items.item.name}}</div>
                                            <div class="text-gray-400">{{items.item.accountName}}</div>
                                        </template>
                                    </dropdown>
                                </div>
                            </div>
                            <div v-show="formPayment.journal == 'Debit'" class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Account Bank</label>
                                </div>
                                <div class="col-span-5">
                                    <dropdown class="w-full" v-model="formPayment.bankName" :items="banks">
                                        <template #default="data">
                                            <div class="cursor-pointer border-b  bg-white flex items-center" :class="{'border-red-500': errPayment.bankId}">
                                                <button class="w-full flex h-full items-center justify-between focus:outline-none">
                                                    <span v-if="formPayment.bankName">{{formPayment.bankName}}</span>
                                                    <span v-else class="text-gray-400">Select account bank</span>
                                                    <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #items="items">
                                            <div>{{items.item.name}}</div>
                                            <div class="text-gray-400">{{items.item.accountName}}</div>
                                        </template>
                                    </dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1">
                            <div class="mb-5">
                                <div class="font-medium text-base">{{formPayment.invoiceNo}}</div>
                                <div class="text-gray-400 text-xs">INVOICE #</div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Amount</label>
                                </div>
                                <div class="col-span-5">
                                    <autonumeric
                                        :class="{'border-red-500': errPayment.amount}"
                                        v-model="formPayment.amount"
                                        @keydown.native="errPayment.amount = ''"
                                        class="w-full border-b focus:outline-none group-hover:bg-gray-100 mb-2"
                                        :options="['integer', {modifyValueOnWheel : false}]" 
                                    >
                                    </autonumeric>
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Payment Date</label>
                                </div>
                                <div class="col-span-5">
                                    <date-picker v-model="formPayment.paymentDate" :masks="masks">
                                        <template v-slot="{inputValue, togglePopover}">
                                            <input :value="inputValue" type="text" @click="togglePopover()" class="border-b focus:outline-none w-full" readonly>
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-2 text-right px-5 border-t">
                    <button @click="modalPayment = false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                    <button type="submit" class="h-9 px-5 bg-teal-500 rounded-sm text-teal-50" :disabled="isDisabled">CREATE PAYMNET</button>
                </div>
            </form>
        </modal>
        <div class="hidden">
            <invoice-pdf :data="invoice" id="printJS-form"></invoice-pdf>
        </div>
        <div class="hidden">
            <pro-forma :data="proForma" id="printPro-forma"></pro-forma>
        </div>
	</div>
</template> 
<script>
import BreadcrumbsManager from '../../../mixins/breadcrumbsManager.vue'
import stepper from '../../../components/stepper.vue'
import axios from 'axios'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Autonumeric from '../../../components/Autonumeric.vue'
import {moment} from '../../../moment'
import {EventBus} from '../../../event-bus'
import Modal from '../../../components/Modal.vue'
import Dropdown from '../../../components/Dropdown.vue'
import TaskManager from '../../../components/taskManager.vue'
import invoicePdf from '../../../components/invoicePdf.vue'
import proForma from '../../../components/proForma.vue'
import printJS from 'print-js'
import Swal from 'sweetalert2/dist/sweetalert2'
import Select2 from '../../../components/Select2.vue'
export default {
	components: {stepper, Autonumeric, DatePicker, Modal, Dropdown, TaskManager, invoicePdf, proForma, Select2},
	mixins: [BreadcrumbsManager],
	data () {
		return {
            isDisabled: false,
            isEdited: false,
			tab: 'overview',
            modalPayment: false,
            masks: {
                input: 'DD/MM/YYYY'
            },
            payments: [],
            journals: [{name: 'Bank'},{name: 'Debit'}, {name: 'Cash'}],
            banks: [],
			task: 0,
            form: {
                customer: '',
                billTo: '',
                shipTo: '',
                items: [],
                downPayments: [{amount: 0}],
                bankId: '',
                total: '',
                totalAdditionalCharges: '',
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: 0,
                    bookingCode: '',
                    trackingNumber: '',
                },
                grandTotal: '',
                amount: ''
            },
            formPayment: {
                journal: 'Bank',
                invoiceNo: '',
                customerName: '',
                invoiceId: '',
                customerId: '',
                paymentDate: new Date(),
                amount: '',
                amountDue: '',
                bankId: '',
                bankName: '',
            },
            errPayment: {
                amount: '',
                bankId: '',
            },
            invoice: '',
            proForma: '',
            downPaymentId: ''
		}
	},
	mounted () {
        this.setButtonText('EDIT')
        this.getData()
        this.getBank()
        this.$refs.banks.templateResult()
        window.addEventListener('click', this.onClose)
        EventBus.$on('onClick', this.onEditInvoice)
        EventBus.$on('onCancel', this.onDiscard)
	},
    destroyed () {
        if(this.isEdited) {
            EventBus.$off('onClick', this.onSaveInvoice)
        } else {
            EventBus.$off('onClick', this.onEditInvoice)
        }
        EventBus.$off('onCancel', this.onDiscard)
        window.removeEventListener('click', this.onClose)
    },
    computed: {
        bankLists () {
            let data = []
            data = this.banks.map(obj => {
                obj.id = obj._id
                obj.text = obj.name
                return obj
            })
            return data
        },
        status () {
            if(this.form.status == 'Posted') {
                return true
            } else {
                return false
            }
        },
        items () {
            return this.form.items.map(obj => {
                obj.total = obj.unitPrice * obj.qty
                obj.subTotal = obj.total - obj.discount.value
                return obj
            })
        },
        downPayments () {
            let t = 0
            if(this.form.type == 'Regular') {
                for (let i=0; i < this.form.downPayments.length; i++) {
                    t += this.form.downPayments[i].amount
                }
            }
            return t
        },
        total () {
            let total = 0
            if(this.form.type == 'Regular') {
                for (let i = 0; i < this.form.items.length; i++) {
                    total += this.form.items[i].subTotal
                }
            }
            return total
        },
        tax () {
            let t = 0
            if(this.form.type == 'Regular') {
                if(this.form.tax.amount) {
                    t = (this.total - this.form.discount.value) * this.form.tax.amount / 100
                }
            }
            return t
        },
        grandTotal () {
            let g = 0
            if(this.form.type == 'Regular') {
                g = this.total + this.tax + this.form.totalAdditionalCharges  + this.form.shipping.shipmentCost - this.form.discount.value
            } else {
                g = this.form.downPayments[0].amount
            }
            return g
        },
        amount () {
            let total = 0
            if(this.payments) {
                for (let i=0; i < this.payments.length; i++) {
                    total += this.payments[i].amount
                }
            }

            return this.grandTotal - total
        }
    },
	methods: {
		onTab (i) {
			this.tab = i
		},
        getData () {
            axios.get('/invoices/detail/'+this.$route.params.id)
            .then(res => {
                this.isEdited = false
                this.invoice = res.data
                this.form = res.data.invoice
                this.payments = res.data.invoice.payments
                if(!this.form.shipping) {
                    this.form.shipping = {
                        shipmentMethodId: '',
                        shipmentService: '',
                        shipmentCost: 0,
                        bookingCode: '',
                        trackingNumber: '',
                    }
                }
                this.pushBreadcrumb({
                    text: this.form.invoiceNo
                })
            })
        },
        getBank () {
            axios.get('/setting/bank')
            .then(res => {
                this.banks = res.data
            })
        },
        onEdit (i) {
            this.onClear()
            this.form.items[i].edited = true
            this.form.items[i].err = false
            let h = this.$refs['td'+i][0].clientHeight +2
            this.$refs['tick'+i][0].style.height = h+'px'
            this.$refs['qty'+i][0].$el.focus()
        },
        onClear () {
            for(let i=0; i < this.form.items.length; i++) {
                this.form.items[i].edited = false
            }
        },
        onClose (e) {
            if(!e || !this.$refs.body.contains(e.target)) {
                this.onClear()
            }
        },
        getDate (d) {
            return moment(d)
        },
        onConfirm () {
            axios.put('/invoices/confirm/'+this.$route.params.id)
            .then((res) => {
                this.replaceBreadcrumb({
                    find: 'Draft Invoice',
                    replace: {text: res.data.invoiceNo}
                })
                this.getData()
            })
        },
        onCanclel () {

        },
        onEditInvoice () {
            this.isEdited = true
        },
        onDiscard () {
            this.isEdited = false
            this.getData()
        },
        onSaveInvoice () {
            this.form.total = this.total
            this.form.grandTotal = this.amount
            axios.put('/invoices/update/'+this.$route.params.id, this.form)
            .then(() => {
                this.getData()
            })
        },
        onSubmitPayment () {
            if(!this.formPayment.amount) {
                this.errPayment.amount = true
            }
            if(this.formPayment.journal == 'Bank' && !this.formPayment.bankId) {
                this.errPayment.bankId = true
            }
            if(this.formPayment.journal == 'Debit' && !this.formPayment.bankId) {
                this.errPayment.bankId = true
            }
            if(this.formPayment.amount && this.formPayment.journal == 'Cash' || this.formPayment.amount && this.formPayment.journal == 'Bank' && this.formPayment.bankId || this.formPayment.amount && this.formPayment.journal == 'Debit' && this.formPayment.bankId) {
                this.isDisabled = true
                axios.post('/payments/create', this.formPayment)
                .then(() => {
                    this.modalPayment = false
                    this.getData()
                    this.isDisabled = false
                })
            }
        },
        onPrint () {
            let invoiceNo = this.invoice.invoice.invoiceNo
            let title = invoiceNo.replace(/\//g, ".") +" ("+ this.invoice.invoice.billTo.customer.name+")"
            document.title = title
            this.$nextTick(() => {
                printJS({
                    printable: 'printJS-form', 
                    type: 'html',
                    css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                    scanStyles: false,
                    documentTitle: title,
                    onPrintDialogClose: () => this.onCancelPrint()
                })
            })
        },
        onPrintProForma () {
            axios.get('/sales/detail/'+this.invoice.invoice.sales._id)
            .then(res => {
                this.proForma = {
                    sales: res.data.sales,
                    invoice: this.invoice.invoice
                }
                let invoiceNo = this.invoice.invoice.invoiceNo.replace(/INV/g, "PI")
                let title = invoiceNo.replace(/\//g, ".") +" ("+ this.invoice.invoice.billTo.customer.name+")"
                document.title = title
                this.$nextTick(() => {
                    printJS({
                        printable: 'printPro-forma', 
                        type: 'html',
                        css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                        scanStyles: false,
                        documentTitle: title,
                        onPrintDialogClose: () => this.onCancelPrint()
                    })
                })
            })
        },
        onCancelPrint () {
            document.title = 'APP DUTAPERSADA'
        },
        onCanceled () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return 
                },
                allowOutsideClick: false
            })
            .then(res => {
                if(res.value) {
                    axios.post('/invoices/cancel/'+this.$route.params.id, this.form, {
                        params: {
                            salesId: this.invoice.invoice.sales._id,
                            downPaymentId: this.downPaymentId,
                        }
                    })
                    .then(res => {
                        console.log(res);
                        this.$router.push('/sales/detail/'+this.invoice.invoice.sales._id)
                        this.setBreadcrumbs([
                            {text: 'Sales Orders', path: '/sales'},
                            {text: this.invoice.invoice.salesNo}
                        ])
                    })
                }
            })
        }
    },
    watch: {
        isEdited (val) {
            if(val) {
                this.setButtonText('SAVE')
                this.setDiscard(true)
                this.setDiscardText('DISCARD')
                EventBus.$off('onClick', this.onEditInvoice)
                EventBus.$on('onClick', this.onSaveInvoice)
            } else {
                this.setButtonText('EDIT')
                this.setDiscard(false)
                this.setDiscardText()
                EventBus.$off('onClick', this.onSaveInvoice)
                EventBus.$on('onClick', this.onEditInvoice)
            }
        },
        modalPayment (val) {
            if(val) {
                console.log(val);
                this.formPayment = {
                    journal: 'Bank',
                    invoiceNo: this.form.invoiceNo,
                    customerName: this.form.billTo.displayName,
                    invoiceId: this.form._id,
                    customerId: this.form.customerId,
                    paymentDate: new Date(),
                    amount: this.amount,
                    amountDue: this.amount,
                    bankId: '',
                    bankName: '',
                }
            } else {
                this.formPayment = {
                    journal: 'Bank',
                    invoiceNo: '',
                    customerName: '',
                    invoiceId: '',
                    customerId: '',
                    paymentDate: new Date(),
                    bankId: '',
                    bankName: '',
                }
            }
        },
        'formPayment.journal': function () {
            this.formPayment.bankName = ''
            this.formPayment.bankId = ''
            this.errPayment.bankId = ''
        },
        'formPayment.bankName': function(val) {
            if(val) {
                let bank = this.banks.find(obj => obj.name == val)
                this.formPayment.bankId = bank._id
                this.errPayment.bankId = ''
            }

        },
        'invoice.invoice.downPayments': function (val) {
            if(val[0]) {
                this.downPaymentId = val[0]
            }
        }
    }
}
</script>