<template>
    <div style="font-size: 12px">
        <div class="bg-white min-h-screen text-black">
            <div class="grid grid-cols-12 h-full gap-5">
                <div class="col-span-12">
                    <div v-if="company" class="flex border-b-2 border-red-600 pb-2">
                        <div>
                            <img :src="imgURL+'/public/img/company-logo.png'" width="80" alt="">
                        </div>
                        <div>
                            <div class="text-2xl font-bold text-red-600">{{company.name}}</div>
                            <div class="text-base italic font-semibold" style="color: blue;">{{company.tagline}}</div>
                            <div>{{company.companyAddress.address}} {{company.companyAddress.city.name}} {{company.companyAddress.postal_code}}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-5 mt-3">
                        <div class="col-span-9">
                            <div class="grid grid-cols-2 gap-5">
                                <div v-if="billTo.customer" class="col-span-1">
                                    <div class="text-red-600">Bill To</div>
                                    <div class="font-semibold">{{billTo.customer.name}}</div>
                                    <div>{{billTo.customer.address.street}} {{billTo.customer.address.street2}}</div>
                                    <div>{{billTo.customer.address.subdistrictName}} {{billTo.customer.address.cityName}} {{billTo.customer.address.zip}}</div>
                                    <div>{{billTo.customer.address.provinceName}}</div>
                                    <div>Phone: {{billTo.customer.contact.phone}}</div>
                                    <div v-if="billTo.attn" class="mt-3 font-semibold italic">Attn: {{billTo.attn}}</div>
                                </div>
                                <div v-if="shipTo" class="col-span-1">
                                    <div class="text-red-600">Ship To</div>
                                    <div class="font-semibold">{{shipTo.parent || shipTo.name}}</div>
                                    <div>{{shipTo.address.street}} {{shipTo.address.street2}}</div>
                                    <div>{{shipTo.address.subdistrictName}} {{shipTo.address.cityName}} {{shipTo.address.zip}}</div>
                                    <div>{{shipTo.address.provinceName}}</div>
                                    <div>Phone: {{shipTo.contact.phone}}</div>
                                    <div v-if="shipTo.attn" class="mt-3 font-semibold italic">Attn: {{shipTo.attn}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-2">
                        <div class="text-2xl font-semibold mb-2">Proforma Invoice {{invoice}}</div>
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="bg-red-600 py-1 px-2 text-white text-left">Invoice Date</th>
                                    <th class="bg-red-600 py-1 px-2 text-white text-left">Due Date</th>
                                    <th class="bg-red-600 py-1 px-2 text-white text-left">Source</th>
                                    <th class="bg-red-600 py-1 px-2 text-white text-left">Customer PO</th>
                                    <th class="bg-red-600 py-1 px-2 text-white text-left">Term Of Payment</th>
                                    <th class="bg-red-600 py-1 px-2 text-white text-left">Ship Via</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border-b">
                                    <td class="px-2 py-1">{{getDate(date)}}</td>
                                    <td class="px-2 py-1">{{getDate(dueDate)}}</td>
                                    <td class="px-2 py-1">{{salesNo}}</td>
                                    <td class="px-2 py-1">{{customerPO}}</td>
                                    <td class="px-2 py-1">{{paymentTerm}}</td>
                                    <td class="px-2 py-1">{{shipVia}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-5">
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="px-2 py-1 bg-red-600 text-red-50" style="width: 8%">Quantity</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50" style="width: 60%">Description</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50 text-right" style="width: 14%">Unit Price</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50 text-right" style="width: 8%">Discount</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50 text-right" style="width: 10%">Total</th>
                                </tr>
                            </thead>
                            <tbody v-if="type == 'Regular'" class="divide-y">
                                <tr v-for="(item) in items" :key="item.idx">
                                    <td class="px-2 py-1">{{item.qty}}</td>
                                    <td class="px-2 py-1">{{item.name}}</td>
                                    <td class="px-2 py-1 text-right">Rp{{Intl.NumberFormat('en-US').format(item.unitPrice)}}</td>
                                    <td class="px-2 py-1 text-right">{{Intl.NumberFormat('en-US').format(item.discount.value)}}</td>
                                    <td class="px-2 py-1 text-right">Rp{{Intl.NumberFormat('en-US').format(item.subTotal)}}</td>
                                </tr>
                                <tr v-for="a in addRow" :key="a">
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-right py-1">Subtotal <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(subTotal)}}</td>
                                </tr>
                                <tr v-if="discount">
                                    <td colspan="4" class="text-right py-1">Discount <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(discount)}}</td>
                                </tr>
                                <tr v-if="taxValue">
                                    <td colspan="4" class="text-right py-1">Tax 11% <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(taxValue)}}</td>
                                </tr>
                                <tr v-if="totalHandling">
                                    <td colspan="4" class="text-right py-1">Shipping & Handling <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(totalHandling)}}</td>
                                </tr>
                                <tr class="border-red-600">
                                    <td colspan="4" class="text-right font-semibold py-1">Total <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(grandTotal)}}</td>
                                </tr>
                                <tr v-for="(pay, d) in payments" :key="d" class="border-transparent">
                                    <td colspan="4" class="text-right font-semibold py-1 italic">Paid On {{getPaidDate(pay.paymentDate)}} <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(pay.amount)}}</td>
                                </tr>
                                <tr class="border-red-600">
                                    <td colspan="4" class="text-right font-semibold py-1">Amount Due <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(amountDue)}}</td>
                                </tr>
                                <tr class="border-red-600"></tr>
                            </tbody>
                            <tbody v-if="type != 'Regular'" class="divide-y">
                                <tr v-for="(item) in items" :key="item.idx">
                                    <td class="px-2 py-1">{{item.qty}}</td>
                                    <td class="px-2 py-1">{{item.name}}</td>
                                    <td class="px-2 py-1 text-right">Rp{{Intl.NumberFormat('en-US').format(item.unitPrice)}}</td>
                                    <td class="px-2 py-1 text-right">{{Intl.NumberFormat('en-US').format(item.discount.value)}}</td>
                                    <td class="px-2 py-1 text-right">Rp{{Intl.NumberFormat('en-US').format(item.subTotal)}}</td>
                                </tr>
                                <tr v-for="a in addRow" :key="a">
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-right py-1">Subtotal <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(subTotal)}}</td>
                                </tr>
                                <tr v-if="discount">
                                    <td colspan="4" class="text-right py-1">Discount <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(discount)}}</td>
                                </tr>
                                <tr v-if="taxValue">
                                    <td colspan="4" class="text-right py-1">Tax 11% <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(taxValue)}}</td>
                                </tr>
                                <tr v-if="totalHandling">
                                    <td colspan="4" class="text-right py-1">Shipping & Handling <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(totalHandling)}}</td>
                                </tr>
                                <tr class="border-red-600">
                                    <td colspan="4" class="text-right font-semibold py-1">Total <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(grandTotal)}}</td>
                                </tr>
                                <tr v-for="(dp, d) in downPayments" :key="d" class="border-transparent">
                                    <td colspan="4" class="text-right font-semibold py-1">{{dp.name}} <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(dp.amount)}}</td>
                                </tr>
                                <tr class="border-red-600">
                                    <td colspan="4" class="text-right font-semibold py-1">Amount Due <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(amountDue)}}</td>
                                </tr>
                                <tr class="border-red-600"></tr>
                            </tbody>
                        </table>
                        <div v-if="bank">
                            <div class="mt-1">
                                All Checks must be cross and addressed to:
                            </div>
                            <div>
                               {{bank.name}}, KCP {{bank.kcp}}
                            </div>
                            <div>
                                A/C Name: <span class="font-semibold">{{bank.accountName}}</span>
                            </div>
                            <div class="flex justify-between">
                                <div>
                                    A/C #: <span class="font-semibold">{{bank.accountNumber}} (IDR) </span>
                                </div>
                                <div class="text-red-600">Thank you for your business!</div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mt-1">
                                All Checks must be cross and addressed to:
                            </div>
                            <div>
                                Bank Central Asia, KCP A. Yani 2, Bandung
                            </div>
                            <div>
                                A/C Name: <span class="font-semibold">PT. DUTA PERSADA INSTRUMENT</span>
                            </div>
                            <div class="flex justify-between">
                                <div>
                                    A/C #: <span class="font-semibold">8090-800-222 (IDR) </span>
                                </div>
                                <div class="text-red-600">Thank you for your business!</div>
                            </div>
                        </div>
                        <div class="mt-3 border p-5 flex items-center justify-between">
                            <img src="@/assets/kyowa.png" width="100" alt="">
                            <img src="@/assets/lutron.png" width="100" alt="">
                            <img src="@/assets/brunton.png" width="100" alt="">
                            <img src="@/assets/estwing.png" width="100" alt="">
                            <img src="@/assets/atlas.png" width="100" alt="">
                        </div>
                        <div class="flex mt-3">
                            <div>
                                <table>
                                    <tr>
                                        <td class="text-red-600">Tel</td>
                                        <td class="pl-3">022.7106162</td>
                                    </tr>
                                    <tr>
                                        <td class="text-red-600">Fax</td>
                                        <td class="pl-3">022.7274932</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="ml-10">
                                <table>
                                    <tr>
                                        <td class="text-red-600">Email</td>
                                        <td v-if="company" class="pl-3">{{company.email}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-red-600">Web</td>
                                        <td v-if="company" class="pl-3">{{company.website}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { moment } from '../moment'
export default {
    props: ['data'],
    data () {
        return {
            imgURL: process.env.VUE_APP_ROOT_API,
            billTo: {
                address: {
                    street: '',
                    street2: ''
                },
                contact: {
                    phone: '',
                },
                displayName: '',
                customer: '',
                attn: '',
            },
            shipTo: {
                address: {
                    street: '',
                    street2: ''
                },
                contact: {
                    phone: ''
                },
                displayName: '',
                customer: '',
                attn: '',
            },
            paymentMethod: '',
            payments: '',
            paymentTerm: '',
            shipVia: '',
            phone: '',
            cellPhone: '',
            fax: '',
            email: '',
            attn: '',
            date: '',
            invoice: '',
            customerPO: '',
            items: [],
            subTotal: '',
            discount: '',
            tax: '',
            taxValue: '',
            shipmentCost: 0,
            additionalChargers: 0,
            grandTotal: 0,
            amountDue: 0,
            offerConditions: '',
            type: '',
            dueDate: '',
            salesNo: '',
            downPayments: [],
            bank: ''
        }
    },
    computed: {
        ...mapGetters({
            company: 'auth/company'
        }),
        addRow () {
            let row = this.items.length
            let t = 0
            if(row < 10) {
                t = 10 - row
                return t
            } else {
                return t
            }
        },
        totalHandling () {
            let t = 0
            t = this.shipmentCost + this.additionalChargers
            return t
        }
    },
    methods: {
        getDate (d) {
            const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Des']
            const date = new Date(d)
            let dd = date.getDate()
            let mm = month[date.getMonth()]
            let yy = date.getFullYear()
            dd = checkTime(dd)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            return `${dd}-${mm}-${yy}`
        },
        getPaidDate (d) {
            return moment (d)
        }
    },
    watch: {
        data (val) {
            if(val) {
                let sales = val.sales
                let invoice = val.invoice
                if(invoice.billTo) {
                    this.billTo = invoice.billTo
                }
                if(invoice.shipTo) {
                    this.shipTo = invoice.shipTo
                }
                this.type = invoice.type
                this.invoice = invoice.invoiceNo.replace(/INV/g, "PI")
                this.salesNo = invoice.salesNo
                this.customerPO = invoice.customerPO
                this.payments = invoice.payments
                this.date = invoice.confirmDate
                this.dueDate = invoice.dueDate
                this.items = sales.items
                this.subTotal = sales.total
                this.grandTotal = sales.grandTotal
                this.amountDue = invoice.amountDue
                if(sales.discount) {
                    this.discount = sales.discount.value
                }
                if(sales.tax) {
                    this.taxValue = sales.tax.value
                }
                if(sales.shipping) {
                    this.shipmentCost = sales.shipping.shipmentCost
                }
                if(sales.totalAdditionalCharges) {
                    this.additionalChargers = sales.totalAdditionalCharges
                }
                if(sales.paymentTerm) {
                    this.paymentTerm = sales.paymentTerm.code
                }
                if(sales.shipVia) {
                    this.shipVia = sales.shipVia.name
                }
                if(invoice.downPayments) {
                    this.downPayments = invoice.downPayments
                }
                if(invoice.bank) {
                    this.bank = invoice.bank
                }
            }
        }
    }
}
</script>